<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <span class="logo">
                            <img
                              src="@/assets/images/logo/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </span>
                        </div>
                        <h4 class="font-size-18 mt-4">Recuperar senha</h4>
                        <p class="text-muted">Recupere sua senha de acesso</p>
                      </div>
                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="passwordRecovery"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon" />
                            <label for="email">E-mail</label>
                            <input
                              type="email"
                              id="email"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.email.$error,
                              }"
                              placeholder="seuemail@goidigital.com.br"
                              v-model="$v.email.$model"
                            />
                            <div
                              v-if="$v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required">
                                O e-mail é obrigatório
                              </span>
                              <span v-if="!$v.email.email">
                                Por favor, insira um email válido
                              </span>
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <button
                              type="submit"
                              class="btn btn-primary w-md waves-effect waves-light"
                              title="Recuperar Senha"
                              :disabled="$v.$invalid || disableButton"
                            >
                              <i
                                v-if="disableButton"
                                class="spinner-grow spinner-grow-custom text-wither"
                              />
                              <span v-else>Recuperar Senha</span>
                            </button>
                          </div>
                          <div class="mt-4 text-center">
                            <router-link
                              to="/"
                              class="text-muted"
                              title="Login"
                            >
                              Login
                            </router-link>
                          </div>
                        </form>
                      </div>
                      <div class="mt-5 text-center">
                        <p>
                          © {{ new Date().getFullYear() }} Go I. Feito com
                          <i class="mdi mdi-heart text-danger" /> por Go I
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordRecovery',

  data() {
    return {
      email: '',
      disableButton: false,
    }
  },

  methods: {
    async passwordRecovery() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'passwordRecovery',
            this.email,
          )

          this.resetForm()

          if (response && response.message) {
            this.$root.$bvToast.toast(response.message, {
              title: 'Sucesso!',
              variant: 'success',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.email = ''
      this.$v.$reset()
    },
  },

  validations: {
    email: {
      required,
      email,
    },
  },
}
</script>
